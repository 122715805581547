@font-face {
  font-family: "Helvetica Now Display Bold";
  src: local("HelveticaNowDisplay-Bold"),
    url("assets/fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("HelveticaNowDisplay-Medium"),
    url("assets/fonts/HelveticaNowDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("HelveticaNowDisplay-Regular"),
    url("assets/fonts/HelveticaNowDisplay-Regular.ttf") format("truetype");
}
